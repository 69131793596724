<template>
  <div class="none">
    <div>
      <img src="@/assets/images/none.png" alt="" />
      <p>该账册正在出具中！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "none",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.none {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    position: relative;
    width: 270px;
    height: 250px;
    text-align: center;
    img {
      width: 100%;
    }
    p {
      position: absolute;
      font-size: 15px;
      color: #b1b1b1;
      top: 192px;
      width: 100%;
    }
  }
}
</style>