<template>
  <div id="anti-fake">
    <div v-if="goods">
      <img :src="goods.filePath" alt="" />
      <!-- <img src="@/assets/zst/goods.png" alt="" /> -->
      <div class="box">
        <div class="title">
          <p style="margin-bottom: 6px">
            商品名称<span>{{ goods.commodityName }}</span>
            <!-- 商品名称<span>正山堂特制金骏眉红茶</span> -->
          </p>
          <p>多维云码<span>83736464643334131122</span></p>
        </div>
        <div class="code">
          <img src="@/assets/images/code2.png" alt="" />
        </div>
      </div>
    </div>
    <none v-else />
  </div>
</template>

<script>
import none from "@/components/none.vue";
export default {
  name: "anti-fake",
  components: {
    none,
  },
  data() {
    return {
      goods: "",
    };
  },

  created() {
    this.goods = JSON.parse(sessionStorage.getItem("goods"));
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
#anti-fake {
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    display: block;
  }

  .box {
    margin: 15px 12px;
    background: #fff;
    border-radius: 8px;
    padding: 0 9px;
    .title {
      font-size: 14px;
      padding: 25px 3px;
      p {
        color: #888888;
        span {
          margin-left: 40px;
          color: #333;
        }
      }
    }
    .code {
      padding: 24px 0;
      border-top: 1px solid #ddd;
      img {
        width: 160px;
        margin: 0 auto;
      }
    }
  }
}
</style>